<template>
  <div class="overlay-wrapper">
    <!-- small collapsed overlay -->
    <div class="overlay-collapsed" v-if="!expanded">
      <b-container>
        <b-row>
          <b-col cols="1"/>
          <b-col cols="1">
            <b-icon class="overlay-collapsed-content overlay-icon" icon="chevron-up" @click="toggleOverlay"/>
          </b-col>
          <b-col cols="8">
            <!-- shows the remaining time -->
            <p class="overlay-collapsed-content">00:35</p>
          </b-col>
          <b-col cols="2"/>
        </b-row>
      </b-container>
    </div>
    <!-- big expanded overlay -->
    <div class="overlay-expanded" v-if="expanded">
      <b-container>
        <b-row>
          <b-col cols="1"/>
          <b-col cols="1">
            <b-icon class="overlay-expanded-header overlay-icon" icon="chevron-down" @click="toggleOverlay"/>
          </b-col>
          <b-col cols="8">
            <!-- shows the remaining time -->
            <p class="overlay-expanded-header">00:35</p>
          </b-col>
          <b-col cols="2"/>
        </b-row>
        <b-row>
          <!-- shows the saved co2 amount -->
          <b-col cols="1"/>
          <b-col cols="1">
            <TreeIcon :co2="bike.power"/>
          </b-col>
          <b-col cols="4">
            <p>{{bike.power}}g CO2</p>
          </b-col>
          <!-- shows the bikes battery level -->
          <b-col cols="1">
            <BatteryIcon v-if="bike.type==='ebike'||1/* remove or*/" :charging="bike.charging" :power="bike.power"/>
          </b-col>
          <b-col cols="4">
            <p>{{bike.power}} Prozent</p>
          </b-col>
          <b-col cols="1"/>
        </b-row>
        <b-row>
          <b-col cols="1"/>
          <b-col cols="10">
            <!-- button to end the drive -->
            <b-button variant="primary" style="width: 100%; margin-top: 1rem;" @click="$router.push('/pause')">Fahrt beenden</b-button>
          </b-col>
          <b-col cols="1"/>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import BatteryIcon from './BatteryIcon';
import TreeIcon from './TreeIcon';
export default {
  name: 'DrivingOverlay',
  data() {
    return {
      expanded: false,
    }
  },
  props: {
    bike: Object,
  },
  components: {
    BatteryIcon,
    TreeIcon,
  },
  methods: {
    toggleOverlay() {
      this.expanded= !this.expanded;
    }
  }
}
</script>

<style scoped>
.overlay-wrapper {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  border-radius: 8px 8px 0px 0px;
  width: 100vw;
}
.overlay-collapsed {
  position: absolute;
  bottom: 0px;
  z-index: 5;
  border-radius: 8px 8px 0px 0px;
  width: 100vw;
  background-color: var(--interaction-color);
  color: var(--white-color);
  height: 3rem;
  line-height: 3rem;
}
.overlay-collapsed-content {
  color: inherit;
  font-size: 1.5rem;
  text-align: center;
}
.overlay-expanded {
  position: absolute;
  bottom: 0px;
  z-index: 5;
  border-radius: 8px 8px 0px 0px;
  width: 100vw;
  background-color: var(--white-color);
  height: 11.25rem;
}
.overlay-expanded-header {
  height: 3rem;
  color: inherit;
  font-size: 1.5rem;
  text-align: center;
  line-height: 3rem;
}
.overlay-icon {
  cursor: pointer;
}
</style>