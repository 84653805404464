<template>
  <div class="treeIconWrapper">
    <!-- shows the co2 level icon corresponding to the saved co2 emission -->
    <img src="@/assets/icons/tree0.svg" class="treeIcon" v-if="co2Calc === 0" alt="co2 Level"/>
    <img src="@/assets/icons/tree1.svg" class="treeIcon" v-if="co2Calc === 1" alt="co2 Level"/>
    <img src="@/assets/icons/tree2.svg" class="treeIcon" v-if="co2Calc === 2" alt="co2 Level"/>
    <img src="@/assets/icons/tree3.svg" class="treeIcon" v-if="co2Calc === 3" alt="co2 Level"/>
    <img src="@/assets/icons/tree4.svg" class="treeIcon" v-if="co2Calc === 4" alt="co2 Level"/>
  </div>
</template>

<script>
export default {
  name: 'TreeIcon',
  data() {
    return {
      loaded: false,
    }
  },
  props: {
    co2: Number,
  },
  computed: {
    co2Calc() {
      // calculates the number of bars inside the icon based on the co2 amount
      // this method gets called each time the co2 amount changes
      const calc = Math.round(this.co2 * (4/100));
      console.log(calc);
      return calc;
    },
  }

}
</script>

<style scoped>
.treeIconWrapper {
  height: 100%;
}
.treeIcon {
  position: absolute;
  bottom: 0px;
  margin-bottom: .5rem;
  width: 2rem;
  height: 2rem;
  color: var(--visual-information-color);
  display: flex;
  align-items: center;
  vertical-align: middle;
}
</style>