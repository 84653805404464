<template>
  <div class="map">
    <transition name="fade">
      <!-- shows the loading screen until the map finished loading -->
      <LoadingScreen v-if="!showMap" />
    </transition>
    <!-- adds the overlay to end the ride and view current stats -->
    <DrivingOverlay :bike="$store.state.bike"/>
    <!-- adds the mapbox component -->
    <MglMap :accessToken="accessToken" :mapStyle="mapStyle" @load="onMapLoad" class="map">
      <MglGeolocateControl :trackUserLocation="true" v-if="showControls" />
      <MglNavigationControl v-if="showControls" />
      <!-- use our custom bike markers on the map -->
      <MglMarker v-if="showMarker" :coordinates="$store.state.bike.coordinates">
        <BikeMarker slot="marker" :type="$store.state.bike.type"/>
      </MglMarker>
    </MglMap>
    <MenuButton/>
  </div>
</template>

<script>
  import Mapbox from "mapbox-gl";
  import DrivingOverlay from '../components/DrivingOverlay.vue';
  import BikeMarker from '../components/BikeMarker.vue';
  import LoadingScreen from '../components/LoadingScreen.vue';
  import MenuButton from '../components/MenuButton';
  import {
    MglMap,
    MglGeolocateControl,
    MglNavigationControl,
    MglMarker,
  } from "vue-mapbox";
  export default {
    name: 'MapComponent',
    components: {
      LoadingScreen,
      DrivingOverlay,
      BikeMarker,
      MenuButton,
      MglMap,
      MglGeolocateControl,
      MglNavigationControl,
      MglMarker,
    },
    data() {
      return {
        accessToken: "pk.eyJ1IjoidGFyYW1vbmhlaW0iLCJhIjoiY2tndGI1bGN2MGpmMTJ5cGI1M3R6ZjliaiJ9.qlDdaIFLkdW9gDptIBojaA",
        mapStyle: 'mapbox://styles/taramonheim/ckilxv1gn2ck717uj0vqz4h9j',
        showMap: false,
        showControls: false,
        showMarker: true,
        zoom: undefined,
      }
    },
    methods: {
      async onMapLoad(event) {
        // jumps to the bikes location on map load
        const asyncActions = event.component.actions
        await asyncActions.jumpTo({
          center: this.$store.state.bike.coordinates,
          zoom: 20
          // shows the map controls after a short delay
        }).then(() => setTimeout(() => this.showControls = true, 500));
        // hides the loading screen
        this.$store.state.mapLoaded = true;
        this.showMap = true;
        // shows a little zoom out animation
        await asyncActions.flyTo({
          center: this.$store.state.bike.coordinates,
          zoom: 18,
          speed: 0.5,
        }).then(() => setTimeout(() => {
          this.showControls = true
          // hides the original bike marker
          setTimeout(() => this.showMarker = false, 500)
        }, 500));
      },
    },
    created() {
      this.mapbox = Mapbox;
    },
    mounted() {
      // checks if the page loaded without any bike data
      if (Object.keys(this.$store.state.bike).length === 0) {
        this.$router.push('/').then(() => this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut',
          'danger'));
      }
    }
  }
</script>

<style scoped>
  .map {
    width: 100vw;
    height: 100vh;
  }

  .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-leave-to {
    opacity: 0;
  }
</style>