<template>
  <div class="home">
    <DrivingMap/>
  </div>
</template>

<script>
  import DrivingMap from '../components/DrivingMap';
  export default {
    name: 'Driving',
    components: {
      DrivingMap,
    }
  }
</script>

<style scoped>

</style>